.sidebar-calendar {
	margin-top: .75rem;
	padding: .5rem;
	color: #fff;
	background: #E65C5C linear-gradient(to right bottom, #E65C5C, #F3978C);
	border-radius: .3rem;

	.hd {
		font-size: 90%;
	}
}

.bs-cal-container {
	max-width: 400px;
	font-size: 80%;
	margin-x: auto;
	cursor: default;
}

// タイトル色
.bs-cal-title,
.bs-cal-nav-btn path {
	color: $fg-main;
	fill: $fg-main;
}

// タイトル、前の月、次の月
.bs-cal-title,
.bs-cal-nav-btn {
	clickable-style();
	padding: .25rem .5rem;
	border-radius: 1.5rem;
}

.bs-cal-nav-btn:disabled {
	&,
	&:hover,
	&:focus {
		&:before {
			background: transparent;
		}
	}
}

.bs-cal-title {
	font-size: 112.5%;
}

// カレンダー部分
.bs-cal-body {
	padding: 0 0 1px 1px;
	background: #A9A9A9;
}

// 日付セル
.bs-cal-col {
	margin: 1px 1px 0 0;
	color: #444;
	background: #fff;

	&:first-child {
		background: #FFF1F1;
	}
	&:last-child {
		background: #EBEEFF;
	}
}

// 曜日
.bs-cal-col-day {
	font-size: 87.5%;
}

// 日曜日
.bs-cal-col-day-0 {
	color: #cb2e43;
}
// 土曜日
.bs-cal-col-day-6 {
	color: #0f80ce;
}

// どんな日?
.bs-cal-col-past {
	// opacity: .4;
}
.bs-cal-col-pad {
	// opacity: .7;
	color: #ccc;
}

.bs-cal-num {
	display: block;
	vertical-align: top;
	font-size: 125%;
	line-height: 2rem;
	white-space: nowrap;
	text-align: center;

	// 今日
	// .bs-cal-col-today & {
	//	color: #fff;
	//	background: #d02c70;
	// }

	// ハイライト
	.bs-cal-col-hl-event & {
		color: #392d00;
		background: #ffc533;
	}
	.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) & {
		clickable-style();
	}
	.bs-cal-col-hl-closed & {
		color: #fff;
		background: #80be4e;
	}
}

// 凡例
.event-calendar-legend-list {
	margin-top: .25rem;
	font-size: 80%;
	text-align: center;
}
.event-calendar-legend-item {
	display: inline-block;
	margin 0 .5em;
}
.event-calendar-legend-bar {
	display: inline-block;
	vertical-align: middle;
	margin-right: .25em;
	size: 3em 1em;
	border-radius: 1em;

	&.event { background: #ffc500; }
	&.closed { background: #80be4e; }
}
