#main .news-list {
	margin: -15px 0 10px;
	padding: 0;

	li + li {
		border-top: solid 1px #ddd;
	}
}

.news-list-item {
	display: flex;
	align-items: flex-start;
	clickable-style();
	padding: 10px 5px;
	color: $fg-main;

	.date {
		width: 9em;
		font-weight: 700;
	}

	.title {
		flex: 1;
	}
}
