.bs-cal-container {
	position: relative;
}

.bs-cal-grid {
	position: relative;
}

.bs-cal-month {
	
}

.bs-cal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.bs-cal-nav-btn {
	padding: 0;
	border: none;
	background: transparent;
}
.bs-cal-nav-prev {
	order: -1;
}
.bs-cal-nav-next {
	order: 2;
}

.bs-cal-row {
	display: flex;
	width: 100%;
}

.bs-cal-col {
	flex: 1;
	text-align: center;
}
