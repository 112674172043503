@charset "UTF-8";

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

html {
  font-size: 100%;
}

a:hover,
a:active {
  outline: 0;
}

html,
body,
div,
span,
p,
pre,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
blockquote,
table,
caption,
thead,
tbody,
tfoot,
tr,
td,
form,
fieldset,
legend,
address {
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

#map_canvas img {
  max-width: none;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

figure {
  margin: 0;
}

ul,
ol {
  list-style: none;
}

@-moz-keyframes bs-progress-bar-stripes {
  0% {
    background-position: 42px 0;
  }

  100% {
    background-position: 0 0;
  }
}

@-webkit-keyframes bs-progress-bar-stripes {
  0% {
    background-position: 42px 0;
  }

  100% {
    background-position: 0 0;
  }
}

@-o-keyframes bs-progress-bar-stripes {
  0% {
    background-position: 42px 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes bs-progress-bar-stripes {
  0% {
    background-position: 42px 0;
  }

  100% {
    background-position: 0 0;
  }
}

.bs-cal-container {
  position: relative;
}

.bs-cal-grid {
  position: relative;
}

.bs-cal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bs-cal-nav-btn {
  padding: 0;
  border: none;
  background: transparent;
}

.bs-cal-nav-prev {
  order: -1;
}

.bs-cal-nav-next {
  order: 2;
}

.bs-cal-row {
  display: flex;
  width: 100%;
}

.bs-cal-col {
  flex: 1;
  text-align: center;
}

.sidebar-calendar {
  margin-top: 0.75rem;
  padding: 0.5rem;
  color: #fff;
  background: #e65c5c linear-gradient(to right bottom, #e65c5c, #f3978c);
  border-radius: 0.3rem;
}

.sidebar-calendar .hd {
  font-size: 90%;
}

.bs-cal-container {
  max-width: 400px;
  font-size: 80%;
  margin-left: auto;
  margin-right: auto;
  cursor: default;
}

.bs-cal-title,
.bs-cal-nav-btn path {
  color: #222;
  fill: #222;
}

.bs-cal-title,
.bs-cal-nav-btn {
  padding: 0.25rem 0.5rem;
  border-radius: 1.5rem;
}

.bs-cal-title,
.bs-cal-nav-btn,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num,
.event-list-item,
.news-list-item {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}

.bs-cal-title:before,
.bs-cal-nav-btn:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:before,
.event-list-item:before,
.news-list-item:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bs-cal-title:hover:before,
.bs-cal-nav-btn:hover:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:hover:before,
.event-list-item:hover:before,
.news-list-item:hover:before,
.bs-cal-title:focus:before,
.bs-cal-nav-btn:focus:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:focus:before,
.event-list-item:focus:before,
.news-list-item:focus:before {
  background: rgba(0,0,0,0.06);
}

.bs-cal-title:active:before,
.bs-cal-nav-btn:active:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:active:before,
.event-list-item:active:before,
.news-list-item:active:before {
  background: rgba(0,0,0,0.26);
}

.bs-cal-title.disabled,
.bs-cal-nav-btn.disabled,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num.disabled,
.event-list-item.disabled,
.news-list-item.disabled,
.bs-cal-title:disabled,
.bs-cal-nav-btn:disabled,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:disabled,
.event-list-item:disabled,
.news-list-item:disabled {
  opacity: 0.5;
  cursor: default;
}

.bs-cal-title.disabled:before,
.bs-cal-nav-btn.disabled:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num.disabled:before,
.event-list-item.disabled:before,
.news-list-item.disabled:before,
.bs-cal-title:disabled:before,
.bs-cal-nav-btn:disabled:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:disabled:before,
.event-list-item:disabled:before,
.news-list-item:disabled:before,
.bs-cal-title.disabled:hover:before,
.bs-cal-nav-btn.disabled:hover:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num.disabled:hover:before,
.event-list-item.disabled:hover:before,
.news-list-item.disabled:hover:before,
.bs-cal-title:disabled:hover:before,
.bs-cal-nav-btn:disabled:hover:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:disabled:hover:before,
.event-list-item:disabled:hover:before,
.news-list-item:disabled:hover:before,
.bs-cal-title.disabled:focus:before,
.bs-cal-nav-btn.disabled:focus:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num.disabled:focus:before,
.event-list-item.disabled:focus:before,
.news-list-item.disabled:focus:before,
.bs-cal-title:disabled:focus:before,
.bs-cal-nav-btn:disabled:focus:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:disabled:focus:before,
.event-list-item:disabled:focus:before,
.news-list-item:disabled:focus:before,
.bs-cal-title.disabled:active:before,
.bs-cal-nav-btn.disabled:active:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num.disabled:active:before,
.event-list-item.disabled:active:before,
.news-list-item.disabled:active:before,
.bs-cal-title:disabled:active:before,
.bs-cal-nav-btn:disabled:active:before,
.bs-cal-col-hl-event:not(.bs-cal-col-hl-closed) .bs-cal-num:disabled:active:before,
.event-list-item:disabled:active:before,
.news-list-item:disabled:active:before {
  background: rgba(128,128,128,0.15);
}

.bs-cal-nav-btn:disabled:before,
.bs-cal-nav-btn:disabled:hover:before,
.bs-cal-nav-btn:disabled:focus:before {
  background: transparent;
}

.bs-cal-title {
  font-size: 112.5%;
}

.bs-cal-body {
  padding: 0 0 1px 1px;
  background: #a9a9a9;
}

.bs-cal-col {
  margin: 1px 1px 0 0;
  color: #444;
  background: #fff;
}

.bs-cal-col:first-child {
  background: #fff1f1;
}

.bs-cal-col:last-child {
  background: #ebeeff;
}

.bs-cal-col-day {
  font-size: 87.5%;
}

.bs-cal-col-day-0 {
  color: #cb2e43;
}

.bs-cal-col-day-6 {
  color: #0f80ce;
}

.bs-cal-col-pad {
  color: #ccc;
}

.bs-cal-num {
  display: block;
  vertical-align: top;
  font-size: 125%;
  line-height: 2rem;
  white-space: nowrap;
  text-align: center;
}

.bs-cal-col-hl-event .bs-cal-num {
  color: #392d00;
  background: #ffc533;
}

.bs-cal-col-hl-closed .bs-cal-num {
  color: #fff;
  background: #80be4e;
}

.event-calendar-legend-list {
  margin-top: 0.25rem;
  font-size: 80%;
  text-align: center;
}

.event-calendar-legend-item {
  display: inline-block;
  margin: 0 0.5em;
}

.event-calendar-legend-bar {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25em;
  width: 3em;
  height: 1em;
  border-radius: 1em;
}

.event-calendar-legend-bar.event {
  background: #ffc500;
}

.event-calendar-legend-bar.closed {
  background: #80be4e;
}

.event-list {
  border-top: solid 1px #eee;
}

.event-list > li {
  border-bottom: solid 1px #eee;
}

.event-list-empty {
  padding: 3rem 0;
  text-align: center;
  color: #aaa;
}

.event-list-item {
  position: relative;
  display: block;
  padding: 1rem;
  padding-left: 5rem;
  color: #222;
}

.event-list-item > .title {
  font-size: 140%;
  font-weight: 700;
  font-feature-settings: "palt", "nlck";
}

.event-list-item .categories {
  line-height: 13px;
}

.event-list-item > .dates {
  display: flex;
  flex-wrap: wrap;
  font-size: 83.33%;
  color: #777;
}

.event-list-item > .dates > :not(:last-child):after {
  display: inline-block;
  content: "/";
  margin: 0 0.4em;
  color: #ccc;
}

.event-list-item .date {
  white-space: nowrap;
}

.event-list-item .date-label {
  margin-right: 0.25em;
  padding: 0.2em 0.4em;
  font-size: 90%;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  background: #1e4abf;
  border-radius: 0.25em;
}

.event-list-item.cancelled {
  opacity: 0.7;
}

.event-badge {
  display: block;
  position: absolute;
  left: 0;
  top: 2.25rem;
  font-size: 0.875rem;
  text-align: center;
  white-space: nowrap;
}

.event-badge-before {
  width: 4.5rem;
  line-height: 2.25rem;
  background: #4fceef;
  border-radius: 1.125rem;
}

.event-badge-after {
  margin-left: 0.125rem;
  width: 4.25rem;
  font-size: 0.8125rem;
  line-height: 2.25rem;
  color: #777;
  background: #d4dde0;
}

.event-badge-in {
  margin-top: -1.125rem;
  width: 4.5rem;
  font-size: 0.9375rem;
  line-height: 4.5rem;
  background: transparent url("../img/event/star-available.svg") no-repeat;
  background-size: contain;
}

.meta-line {
  margin-top: 0.25rem;
  font-size: 83.33%;
  color: #777;
}

.meta-item {
  display: inline-flex;
  align-items: center;
}

.meta-item > dt {
  margin-right: 0.25em;
  padding: 0.2em 0.4em;
  font-size: 90%;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  background: #777;
  border-radius: 0.25em;
}

.label-event-cancelled {
  display: inline-block;
  vertical-align: middle;
  padding: 0.25em 1em;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 0;
  color: #fff;
  background: #cb2a22;
}

h2 .label-event-cancelled {
  font-size: 13px;
}

.kk-label {
  display: inline-block;
  vertical-align: middle;
  min-width: 16px;
  min-height: 16px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  border-radius: 1em;
}

.kk-label-text {
  min-width: 5em;
}

.bg-hue-1 {
  background-color: #f00;
}

.bg-hue-2 {
  background-color: #ff9a00;
}

.bg-hue-3 {
  background-color: #fbd203;
}

.bg-hue-4 {
  background-color: #00e347;
}

.bg-hue-5 {
  background-color: #00b7fb;
}

.bg-hue-6 {
  background-color: #ef46e2;
}

.bg-hue-7 {
  background-color: #a4a4a7;
}

.bg-hue-01 {
  background-color: #fea49b;
}

.bg-hue-02 {
  background-color: #f6c47e;
}

.bg-hue-03 {
  background-color: #fbe37e;
}

.bg-hue-04 {
  background-color: #d2e48e;
}

.bg-hue-05 {
  background-color: #8ac2fb;
}

.bg-hue-06 {
  background-color: #d3b4e3;
}

.bg-hue-07 {
  background-color: #ccc;
}

.event-calendar-legend-item {
  color: #222;
}

#main .news-list {
  margin: -15px 0 10px;
  padding: 0;
}

#main .news-list li + li {
  border-top: solid 1px #ddd;
}

.news-list-item {
  display: flex;
  align-items: flex-start;
  padding: 10px 5px;
  color: #222;
}

.news-list-item .date {
  width: 9em;
  font-weight: 700;
}

.news-list-item .title {
  flex: 1;
}

#main .event-list {
  margin: 1em 0 2em;
  padding: 0;
  line-height: 1.5;
}

#main .meta-item {
  margin: 0;
  padding: 0;
}

#main .meta-item > dt {
  float: none;
  margin: 0 0.25em 0 0;
}

#main .meta-item > dd {
  padding: 0;
}

@media print, screen and (min-width: 751px) {
  .only-mobile {
    display: none;
  }

  .event-cnt {
    display: flex;
    justify-content: space-between;
  }

  .event-cnt > .event-main {
    flex: 1;
    padding-right: 3rem;
  }

  .event-cnt > .event-cal {
    width: 16rem;
  }
}

@media screen and (max-width: 750px) {
  .hidden-mobile {
    display: none;
  }
}