// Support position: sticky
position($position)
	if $position == sticky
		-webkit-position: sticky
	position: $position

// Grid layouts
display($display)
	if $display == grid
		display: -ms-grid
	display: $display

grid-template-rows()
	-ms-grid-template-rows: arguments
	grid-template-rows: arguments

grid-template-columns()
	-ms-grid-template-columns: arguments
	grid-template-columns: arguments

grid-row()
	-ms-grid-row: arguments
	grid-row: arguments

grid-column()
	-ms-grid-column: arguments
	grid-column: arguments

column-gap()
	-ms-column-gap: arguments
	column-gap: arguments

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
backface-visibility(visibility)
	-webkit-backface-visibility: visibility
	backface-visibility: visibility

// User select
// For selecting text on the page
user-select(select)
	-webkit-user-select: select
	-moz-user-select: select
	-ms-user-select: select
	user-select: select

// Optional hyphenation
hyphens(mode = auto)
	word-wrap: break-word;
	-webkit-hyphens: mode // Safari 5.1+
	// -moz-hyphens: mode // Firefox 6-42
	-ms-hyphens: mode // Explorer 10+, Edge
	hyphens: mode // Chrome 55+
