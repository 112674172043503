#main .event-list {
	margin: 1em 0 2em;
	padding: 0;
	line-height: 1.5;
}

#main .meta-item {
	margin: 0;
	padding: 0;

	> dt {
		float: none;
		margin: 0 .25em 0 0;
	}

	> dd {
		padding: 0;
	}
}
