// ================================================================
// 色ラベル

.kk-label {
	display: inline-block;
	vertical-align: middle;
	min-width: 16px;
	min-height: 16px;
	padding: 4px 8px;
	font-size: 13px;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	border-radius: 1em;
}

.kk-label-text {
	min-width: 5em;
}

.bg-hue-1 { background-color: #ff0000; }
.bg-hue-2 { background-color: #ff9a00; }
.bg-hue-3 { background-color: #fbd203; }
.bg-hue-4 { background-color: #00e347; }
.bg-hue-5 { background-color: #00b7fb; }
.bg-hue-6 { background-color: #ef46e2; }
.bg-hue-7 { background-color: #a4a4a7; }

.bg-hue-01 { background-color: #fea49b; }
.bg-hue-02 { background-color: #f6c47e; }
.bg-hue-03 { background-color: #fbe37e; }
.bg-hue-04 { background-color: #d2e48e; }
.bg-hue-05 { background-color: #8ac2fb; }
.bg-hue-06 { background-color: #d3b4e3; }
.bg-hue-07 { background-color: #cccccc; }
