$min-xlarge = 1440px;
$min-large = 1261px;
$min-desktop = 881px;
$min-tablet = 751px;
$max-small = 481px;
$max-xsmall = 375px;

$max-not-large = $min-large - 1px;
$max-tablet = $min-desktop - 1px;
$max-mobile = $min-tablet - 1px;
$min-not-small = $max-small - 1px;
$min-not-xsmall = $max-xsmall - 1px;

media-xlarge()
	@media screen and (min-width: $min-xlarge)
		{block}

media-large()
	@media screen and (min-width: $min-large)
		{block}

media-not-large()
	@media print, screen and (min-width: $min-desktop) and (min-width: $max-not-large)
		{block}

media-desktop()
	@media print, screen and (min-width: $min-desktop)
		{block}

media-min-tablet()
	@media print, screen and (min-width: $min-tablet)
		{block}

media-tablet()
	@media screen and (min-width: $min-tablet) and (max-width: $max-tablet)
		{block}

media-max-tablet()
	@media screen and (max-width: $max-tablet)
		{block}

media-mobile()
	@media screen and (max-width: $max-mobile)
		{block}

media-not-small()
	@media screen and (min-width: $min-not-small) and (max-width: $max-mobile)
		{block}

media-not-xsmall()
	@media screen and (min-width: $min-not-xsmall) and (max-width: $max-mobile)
		{block}

media-small()
	@media screen and (max-width: $max-small)
		{block}

media-xsmall()
	@media screen and (max-width: $max-xsmall)
		{block}

// 高解像度
media-hires()
	@media print, screen and (min-device-pixel-ratio: 1.5)
		{block}

// Helper classes
+media-mobile() {
	.hidden-mobile {
		display: none !important;
	}
}

+media-min-tablet() {
	.only-mobile {
		display: none !important;
	}
}
