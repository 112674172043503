// ----------------------------------------------------------------
// :hover で色を濃く、 :active でさらに暗くするスタイル
// ::before 疑似要素を使用する

clickable-style() {
	+cache() {
		position: relative;
		overflow: hidden;
		text-decoration: none;
		cursor: pointer;

		&:before {
			pseudo-block();
			position: absolute;
			z-index: 99;
			inset: 0;
		}
		&:hover,
		&:focus {
			&:before {
				background: fade(#000, 6%);
			}
		}
		&:active {
			&:before {
				background: fade(#000, 26%);
			}
		}

		&.disabled,
		&:disabled {
			opacity: .5;
			cursor: default;

			&,
			&:hover,
			&:focus,
			&:active {
				&:before {
					background: fade($fg-muted, 15%);
				}
			}
		}
	}
}
