@charset "UTF-8";

@import "../public/defs/font";

@import "../stylus-lib/less-color-fn";
@import "../stylus-lib/polyfill";
@import "../stylus-lib/mixins";
@import "../stylus-lib/reset";
@import "../stylus-lib/gradation";
@import "../stylus-lib/progress-bar";
@import "../stylus-lib/make-paginator";

@import "../public/defs/color";
@import "../public/defs/responsive";

// @import "../public/base";
// @import "../public/layout";
// @import "../public/print";

// @import "../public/parts/page";
@import "../public/parts/clickable";
// @import "../public/parts/article";
// @import "../public/parts/paragraph";
// @import "../public/parts/heading";
// @import "../public/parts/figure";
// @import "../public/parts/file-type";
// @import "../public/parts/file-box";
// @import "../public/parts/video";
// @import "../public/parts/map";
// @import "../public/parts/table";
// @import "../public/parts/button";
// @import "../public/parts/form";
// @import "../public/parts/user-form";
// @import "../public/parts/paginator";
// @import "../public/parts/index";
// @import "../public/parts/action";
// @import "../public/parts/nav";
// @import "../public/parts/text";
// @import "../public/parts/misc";

@import "../public/plugins/bs-cal";
@import "../public/plugins/bs-cal-theme";

// @import "../public/sections/header";
// @import "../public/sections/header-mobile";
// @import "../public/sections/footer";
@import "../public/sections/event-list";
// @import "../public/sections/event-view";
@import "../public/sections/event-label";
// @import "../public/sections/event-category-nav";
// @import "../public/sections/page-error";

.event-calendar-legend-item {
	color: $fg-main;
}

@import "news-list";
@import "event-list";
