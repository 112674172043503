@keyframes bs-progress-bar-stripes
	0%
		background-position: 42px 0;
	100%
		background-position: 0 0;

progress-bar($width, $height, $color, $bg)
	width: $width;
	height: $height;
	
	-webkit-appearance: none;
	appearance: none;
	
	make-progress-bar-bar: $bg;
	overflow: hidden;

	&::-webkit-progress-bar
		make-progress-bar-bar: $bg;

	&::-webkit-progress-value
		make-progress-bar-value: $color;

	&:not([value])::-webkit-progress-bar
		make-progress-bar-indeterminate: $color;

	&::-moz-progress-bar
		make-progress-bar-value: $color;

	&:indeterminate::-moz-progress-bar
		make-progress-bar-indeterminate: $color;

progress-bar-bar($bg)
	background: $bg;
	box-shadow: inset 0 1px 2px rgba(#000, .2);
	border: none;
	border-radius: 3px;

progress-bar-value($color)
	background-color: $color;
	background-image: linear-gradient(45deg, rgba(#fff, .15) 25%, transparent 25%, transparent 50%, rgba(#fff, .15) 50%, rgba(#fff, .15) 75%, transparent 75%, transparent);
	background-size: 42px 42px;
	animation: bs-progress-bar-stripes 2s linear infinite; // Not works in pseudo element

progress-bar-indeterminate($color)
	background-color: transparent;
	background-image: linear-gradient(45deg, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-size: 42px 42px;
	animation: bs-progress-bar-stripes 2s linear infinite; // Not works in pseudo element
