.event-cnt {
	+media-min-tablet() {
		display: flex;
		justify-content: space-between;

		> .event-main {
			flex: 1;
			padding-right: 3rem;
		}

		> .event-cal {
			width: 16rem;
		}
	}
}

.event-list {
	border-top: solid 1px #eee;

	> li {
		border-bottom: solid 1px #eee;
	}
}

.event-list-empty {
	padding: 3rem 0;
	text-align: center;
	color: #aaa;
}

.event-list-item {
	position: relative;
	display: block;
	clickable-style();
	padding: 1rem;
	padding-left: 5rem;
	color: $fg-main;

	> .title {
		font-size: 140%;
		font-weight: 700;
		OT-proportional();
	}

	.categories {
		line-height: 13px;
	}

	> .dates {
		display: flex;
		flex-wrap: wrap;
		font-size: 83.33%;
		color: #777;

		> :not(:last-child):after {
			display: inline-block;
			content: "/";
			margin: 0 .4em;
			color: #ccc;
		}
	}
	.date {
		white-space: nowrap;
	}

	.date-label {
		margin-right: .25em;
		padding: .2em .4em;
		font-size: 90%;
		font-weight: 400;
		line-height: 1.2;
		color: #fff;
		background: #1e4abf;
		border-radius: .25em;
	}

	&.cancelled {
		opacity: .7;
	}
}

.event-badge {
	display: block;
	position: absolute;
	left: 0;
	top: 2.25rem;
	font-size: .875rem;
	text-align: center;
	white-space: nowrap;
}
.event-badge-before {
	width: 4.5rem;
	line-height: 2.25rem;
	background: #4FCEEF;
	border-radius: 1.125rem;
}
.event-badge-after {
	margin-left: .125rem;
	width: 4.25rem;
	font-size: .8125rem;
	line-height: 2.25rem;
	color: #777;
	background: #D4DDE0;
}
.event-badge-in {
	margin-top: -1.125rem;
	width: 4.5rem;
	font-size: .9375rem;
	line-height: 4.5rem;
	background: transparent url(../img/event/star-available.svg) no-repeat;
	background-size: contain;
}

.meta-line {
	margin-top: .25rem;
	font-size: 83.33%;
	color: #777;
}

.meta-item {
	display: inline-flex;
	align-items: center;

	> dt {
		margin-right: .25em;
		padding: .2em .4em;
		font-size: 90%;
		font-weight: 400;
		line-height: 1.2;
		color: #fff;
		background: #777;
		border-radius: .25em;
	}
}

.label-event-cancelled {
	display: inline-block;
	vertical-align: middle;
	padding: .25em 1em;
	letter-spacing: .2em;
	text-indent: .2em;
	font-size: .8rem;
	line-height: 1.2;
	border-radius: 0;
	color: #fff;
	background: #cb2a22;

	h2 & {
		font-size: 13px;
	}
}
