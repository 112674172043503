// Clearfix
// --------
clearfix()
	+cache()
		&::after
			content: ""
			display: block
			line-height: 0
			clear: both

// Webkit-style focus
// ------------------
tab-focus()
	// Default
	outline: thin dotted #333
	// Webkit
	outline: 5px auto -webkit-focus-ring-color
	outline-offset: -2px

// Center-align a block level element
// ----------------------------------
center-block()
	display: block
	margin-left: auto
	margin-right: auto

// Set pseudo element as a block level element
pseudo-block()
	content: ""
	display: block

// Set pseudo element as a inline-block level element
pseudo-inline-block()
	content: ""
	display: inline-block

// Sizing shortcuts
// -------------------------
size(width, height = width)
	width: width
	height: height

// Margin/padding shortcuts
// -------------------------
margin-x(val)
	margin-left: val
	margin-right: val
margin-y(val)
	margin-top: val
	margin-bottom: val
padding-x(val)
	padding-left: val
	padding-right: val
padding-y(val)
	padding-top: val
	padding-bottom: val

// Positioning shortcuts
// -------------------------
posxy(left, top)
	left: left
	top: top

inset(top, right = top, bottom = top, left = right)
	top: top
	right: right
	bottom: bottom
	left: left

// Placeholder color
// -------------------------
placeholder(color)
	&:-moz-placeholder
		color: color
	&:-ms-input-placeholder
		color: color
	&::-webkit-input-placeholder
		color: color

// Text overflow
// -------------------------
// Requires inline-block or block for proper styling
text-ellipsis()
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap

// CSS image replacement
// -------------------------
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
hide-text()
	font: 0/0 a
	color: transparent
	text-shadow: none
	background-color: transparent
	border: 0

// OpenType features
OT-proportional()
	font-feature-settings: "palt", "nlck"
